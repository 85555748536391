import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';

//sections
import promoSection from '@/modules/home/section/promo/index.vue';
import howSection from '@/modules/home/section/how/index.vue';
import partnersSection from '@/modules/home/section/partners/index.vue';
import feedbackSection from '@/modules/home/section/feedback/index.vue';

export default {
  name: "home",
  components:{
    promoSection,
    howSection,
    partnersSection,
    feedbackSection
  },
  data(){
    return{

    }
  },
  created() {
    this.getSteps()
    this.getPartners()
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      steps: 'home/steps',
      partners: 'home/partners',
    }),
  },

  methods:{
    ...mapActions({
      getSteps: 'home/GET_STEPS',
      getPartners: 'home/GET_PARTNERS',
    }),
    ...mapMutations({
    })
  }
}