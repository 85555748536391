import {mapMutations} from "vuex";
export default {
  name: "partners",
  props: {
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components:{
  },
  data(){
    return{

    }
  },
  created() {
  },
  computed:{

  },
  methods:{
    ...mapMutations({
    })
  }
}