import {mapMutations} from "vuex";

export default {
  name: "how",
  props: {
    list: {
      type: Array,
      default: ()=> []
    }
  },
  components: {},
  data() {
    return {}
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapMutations({}),
    nextBlock(id) {
      const element = document.getElementById(id);
      const headerOffset = 200;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }
}