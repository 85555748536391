import {mapGetters, mapMutations} from "vuex";


export default {
  name: "promo",
  components:{
  },
  data(){
    return{
      scroller: true,
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll',()=>{
      window.scrollY > 90 ? this.scroller = false : this.scroller = true
    })
    window.scrollY > 90 ? this.scroller = false : this.scroller = true
  },
  computed:{
    ...mapGetters({
      variables: 'setting/variables'
    }),
    bannerSrc(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'banner')
      }
      else return ''
    },
    instagram(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'instagram')
      }
      else return ''
    },
    facebook(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'facebook')
      }
      else return ''
    },
    linkedin(){
      if(this.variables !== null){
        return this.variables.find(item => item.key === 'linkedin')
      }
      else return ''
    },
  },
  methods:{
    ...mapMutations({
    })
  }
}